<template>
    <label :data-tooltip="tooltip" :data-invalid="error" :class="{ 'show-invalid' : showInvalidMessage }">
        <textarea ref="textarea" :required="required" v-model="value" :placeholder="placeholder" cols="30" rows="5" @keyup="validate" @focus="$emit('focus')" :class="{ 'single' : single }"></textarea>
    </label>
</template>

<script>
export default {
    name: 'Input',
    props: {
        placeholder: {
            type: String,
            default: '',
        },
        tooltip: {
            type: String,
            default: null,
        },
        required: {
            type: Boolean,
            default: false,
        },
        showInvalidMessage: {
            type: Boolean,
            default: false,
        },
        single: {
            type: Boolean,
            default: false,
        },
        modelValue: String,
    },
    data() {
        return {
            error: null,
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },
    },
    methods: {
        validate(e) {
            if (this.required && e.code !== 'Tab') {
                this.error = this.value ? null : this.$refs.textarea.validationMessage;
            }
        },
    },
};
</script>
